.CVMain{
    
    
    margin: 15% ;
    
    
    
   
    
    
}

.CVMain h1{
    margin: 20px;font-family: 'Sacramento',
    cursive;
    font-size: 7rem;
}
.CVMain h2{
    font-family: 'Montserrat',
    sans-serif;
    font-size: 3.5rem;
}

@media (max-width: 425px){
    .CVMain h1{
        font-size: 4rem;
    }
    .CVMain h2{
        font-size: 2rem;
    }
    .CVMain{
        margin: 65% 0;
    }
}