.Projects{
   left: 0;
   top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
    align-items: center;
    justify-content: space-evenly;
    
}
.ProjectsMain{
    left: 0;
    top: 0;
    /* height: 100vh; */
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}