.ContactMe{
    display: flex;
    flex-direction: column;
    align-content: center;
    font-family: 'Montserrat',sans-serif;
    width: 100%;
}
.ContactMeForm{
    display: flex;
    flex-direction: column;
    width: 70%;
    outline: none;
    margin: 2.5% 12.5%;
    padding: 5%;
    align-content: center;
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 0 3px 50px #ccc;
}
.ContactMeForm input{
    margin: 20px;
}
.ContactMeForm textarea{
    margin: 20px;
}
.ContactMeForm button{
    outline: none;
    margin: 0 40%;
    width: 20%;
    height: 10%;
    background-color: white;
    border-radius: 10%;
    cursor: pointer;
}


@media(max-width:425px){
    .ContactMe{
        width: 100%
    }
}