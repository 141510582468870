.Layout{
    left: 0;
    top: 0;
    z-index: 201;
    align-items: center;
    margin: 0px;
    height: 170%;
    width: 100%;
    background-color: rgba(0, 0, 0,0.7);
    
    
    
}

/*  font-family: 'Merriweather', serif;

  font-family: 'Montserrat', sans-serif;
} */

.LayoutImg{
    margin: 0;
    padding: 0;
    left: 0;
    top:0;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -200;
    
}
@media(max-width:425px){
  .Layout{
    height: 210%;
  }
  
  
}