.AboutMeMain{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AboutMe{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.AboutMe_Title{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-items: flex-start;
    width: 80%;
    height: 20%;
    margin: 0 10%;
    padding: 50px;
    background-color: black;
    box-shadow: 0 3px 50px #ccc;
    font-family: 'Merriweather',
    serif;

}
.AboutMe_Title img{
    
    display: flex;
    justify-content: flex-start;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    border: 10px solid white;
    position: relative;
}
.AboutMe_Lang h2,
.AboutMe_Info h2,
.AboutMe_Title_wording h1{
    margin: 20px;
    font-family: 'Sacramento',
    cursive;
    font-size: 3rem;
}
.AboutMe_Title_wording{
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    align-content: center;
    
}
.AboutMe_Lang,
.AboutMe_Info{
    
    display: flex;
    flex-direction: column;
    
    width: 80%;
    height: 20%;
    margin: 1% 10%;
    padding: 50px;
    background-color: black;
    box-shadow: 0 3px 50px #ccc;
    font-family: 'Merriweather',
    serif;
}

.AboutMe_Lang ul{
    display: flex;
    flex-direction: column;
    margin: 1% 0;

    list-style: none;

}
.AboutMe_Lang li{
    margin: 1% 0;
}
.AboutMe_Lang svg{
    margin-right: 10px;
}


@media(max-width:550px){
    .AboutMe_Title{
        justify-content: center;
        flex-wrap: wrap;
    }
    .AboutMe_Lang ul,
    .AboutMe_Lang li{
        margin: 3% 0;
    }
    
}
