
.App {
  text-align: center;
  
  color: white;
  margin: 0;
  height: 100vh;
  width: 100vw;
  z-index: 200; 
}


