.NavItem{
    list-style: none;
    text-decoration: none;
    display: inline-block;
    margin: 20px;
    padding: 10px;
    font-size: 25px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    
    
}
.NavItem a
{
    text-decoration: none;
    color: white;
}
.NavItem a.active,
.NavItem a:hover{
    border-bottom: 5px solid white;
    
}
@media(max-width: 728px) {
    .NavItem a{
        display: none;
    }
}