.navBar {
    position: fixed;
    top: 2rem;
    left: 0;
    z-index: 99;
}

.menuBars {
    margin-left: 2rem;
    font-size: 2.5rem;
    background: none;
    z-index: 99;
}

.NavMenu {
    background-color: black;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;

}

.NavMenuActive {
    background-color: black;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    transition: 350ms;
    z-index: 100;
}

.NavText {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;

}

.NavText a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;

}

.NavText a:hover,
.NavText a.active
{
    border-bottom: 5px solid white;
}

.navMenuItems {
    width: 100%;
}

.navBarToggle {
    
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

span {
    margin-left: 16px;
}

@media (min-width:720px) {
    .menuBars {
        display: none;
    }
}