.Footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-end;
    color: white;
    margin-top: 10%;
    
    z-index: 300;
    font-family:'Montserrat',
    sans-serif;
}

.Icons svg{
    margin: 10px;
    color: white;
    z-index: 300;
}
.Icons a{
    cursor: pointer;
}