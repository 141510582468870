.Banner{
    margin: 2.5% 10%;
    padding: 2.5% 7.5%;
    background-color: rgba(0, 0, 0, 1);
    width: 70%;
    height: 2.5%;
    box-shadow: 0 3px 50px #ccc;
    font-family: 'Montserrat',
    sans-serif;
   
}

@media(max-width: 425px){
    .Banner{
        width: 70%;
    }
}